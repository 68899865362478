import styled from 'styled-components';

export const PieChartContainerWrapper = styled.div``;
export const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  /* padding: 0 50px; */
  width: 100%;
`;
export const TextCenter = styled.h3`
  text-align: center;
`;
