import { Pie } from '@ant-design/plots';
import React from 'react';
import { TextCenter } from '../HomeLaos/PieChartContainer/pieChartContainerStyle';
import { PieChartWrapper } from './pieChartStyle';

const PieChart = ({ pieData, pieName }) => {
  const config = {
    appendPadding: 10,
    data: pieData,
    angleField: 'value',
    colorField: 'type',
    radius: 0.8,
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }) => `${percent * 100}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'pie-legend-active',
      },
      {
        type: 'element-active',
      },
    ],

    legend: {
      position: 'bottom',
      itemName: {
        style: {
          fill: '#000',
        },
        formatter: (name) => name,
      },
      itemSpacing: 0,
    },
  };
  return (
    <PieChartWrapper>
      <Pie {...config} />
      <TextCenter>{pieName}</TextCenter>
    </PieChartWrapper>
  );
};

export default PieChart;
