/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import Document from "./document";

import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Menu, Segmented } from "antd";

import iconLaos from "../assets/icon/laos.png";
import iconUnitedStates from "../assets/icon/united-states.png";
import RowData from "./row-data";
import SurveyLink from "./survey";
import ExportData from "./export-data";
import FilterComponent from "../dashboard/filter";
import UserManager from "./../pages/users/index";

import { sendGet } from "../api/axios";
import { EDepartment } from "../common/const";
import { SCREEN_DEFAULT } from "../common/ngok";
import {
  storeSetCitiesData,
  storeSetCitySelected,
  storeSetDashboardData,
  storeSethospitalSelected,
  storeSetHostpitalData,
  storeSetTableData,
} from "../store/data-reducer";

import {
  Buttonanguage,
  ContainerWrapper,
  IConLanguage,
  PathWrapper,
  WrapperExport,
} from "./styled";
import HomeLaos from "./Home/HomeLaos";
import { storeSetLanguage } from "../store/auth-reducer";
import i18next from "i18next";

const AppContainer = ({ screen, title, setScreen }) => {
  const dispath = useDispatch();
  const location = useLocation();
  const patch = location?.pathname || "/dashboard";
  const isCollapse = useSelector((state) => state.dashboard.isCollapse);

  const hospitalSelected = useSelector(
    (state) => state?.data?.hospitalSelected
  );

  const [isLoading, setIsLoading] = useState(false);

  const getDataDashboard = async (selectedCode) => {
    try {
      setIsLoading(true);
      const url =
        selectedCode && selectedCode !== -1
          ? `/dm/data/hospitals/indicators?hosp_id=${selectedCode}`
          : `/dm/data/country/avg_indicators`;
      console.log(url);
      const response = await sendGet(url);
      if (response?.data) {
        dispath(storeSetDashboardData(response?.data));
      }
    } catch (error) {
      dispath(storeSetDashboardData(null));
    } finally {
      setIsLoading(false);
    }
  };

  const getOverView = async (selectedCode) => {
    try {
      const response = await sendGet(
        `/dm/data/evaluation/overview?hospital=${selectedCode}`
      );
      if (response?.status === "successful") {
        dispath(storeSetTableData(response?.data));
      }
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    getDataDashboard(hospitalSelected?.code);
    if (hospitalSelected?.code && hospitalSelected?.code !== -1) {
      getOverView(hospitalSelected?.code);
    } else {
      dispath(storeSetTableData(null));
    }
  }, [hospitalSelected?.code]);

  return (
    <ContainerWrapper isCollapse={isCollapse}>
      <div className="header">
        <PathComponent screen={screen} setScreen={setScreen} />
        <FilterComponent
          disabled={
            patch === SCREEN_DEFAULT[2] ||
            patch === SCREEN_DEFAULT[4] ||
            patch === SCREEN_DEFAULT[6] ||
            patch === SCREEN_DEFAULT[7] ||
            patch === SCREEN_DEFAULT[3]
          }
          screen={screen}
          setScreen={setScreen}
        />
      </div>
      {patch === SCREEN_DEFAULT[1] || patch === "/" ? (
        <WrapperExport>
          <HomeLaos isLoading={isLoading} />
        </WrapperExport>
      ) : (
        <div />
      )}
      {patch === SCREEN_DEFAULT[2] && <SurveyLink />}
      {patch === SCREEN_DEFAULT[3] && <RowData />}
      {patch === SCREEN_DEFAULT[4] && <Document title={title} />}
      {patch === SCREEN_DEFAULT[6] && <ExportData />}
      {patch === SCREEN_DEFAULT[7] && <UserManager />}
    </ContainerWrapper>
  );
};

export default AppContainer;

function PathComponent({ screen, setScreen }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const citiesData = useSelector((state) => state.data.citiesData);
  const citySelected = useSelector((state) => state.data.citySelected);
  const hostPitals = useSelector((state) => state.data.hostPitals);
  const hospitalSelected = useSelector(
    (state) => state?.data?.hospitalSelected
  );
  const language = useSelector((state) => state?.auth?.language);
  const setLanguage = (languageChange) => {
    dispatch(storeSetLanguage(languageChange));
  };

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <div
              onClick={() => {
                const reDataCity = citiesData?.map((element) => ({
                  ...element,
                  name: element?.laos_name || "",
                }));
                dispatch(
                  storeSetCitySelected({
                    ...citySelected,
                    name: citySelected?.laos_name || "",
                  })
                );

                const reDataHostpital = hostPitals?.map((element) => ({
                  ...element,
                  name: element?.laos_name,
                }));
                dispatch(
                  storeSethospitalSelected({
                    ...hospitalSelected,
                    name: hospitalSelected?.laos_name || "",
                  })
                );
                dispatch(storeSetHostpitalData(reDataHostpital));

                dispatch(storeSetCitiesData(reDataCity || []));
                i18next.changeLanguage("la");
                setLanguage("la");
              }}
            >
              <IConLanguage src={iconLaos} alt="" /> {t("common.laos")}
            </div>
          ),
        },
        {
          key: "2",
          label: (
            <div
              onClick={() => {
                i18next.changeLanguage("en");
                setLanguage("en");
                const reDataCity = citiesData?.map((element) => ({
                  ...element,
                  name: element.vie_name,
                }));
                dispatch(
                  storeSetCitySelected({
                    ...citySelected,
                    name: citySelected?.vie_name || "",
                  })
                );

                const reDataHostpital = hostPitals?.map((element) => ({
                  ...element,
                  name: element.vie_name || "",
                }));

                dispatch(
                  storeSethospitalSelected({
                    ...hospitalSelected,
                    name: hospitalSelected?.vie_name || "",
                  })
                );
                dispatch(storeSetHostpitalData(reDataHostpital));
                dispatch(storeSetCitiesData(reDataCity || []));
              }}
            >
              <IConLanguage src={iconUnitedStates} alt="" />{" "}
              {t("common.engLish")}
            </div>
          ),
        },
      ]}
    />
  );
  const screenFake = [
    t("screen.surveyResults"),
    t("screen.surveyLink"),
    t("screen.rowData"),
    t("screen.regulations"),
    t("screen.exportReport"),
  ];
  return (
    <PathWrapper>
      <div>
        <span
          onClick={() => {
            dispatch(storeSetCitySelected(null));
            dispatch(storeSethospitalSelected(null));
            setScreen(1);
            navigate("/");
            // window.location.reload();
          }}
        >
          {t("screen.home")}
        </span>{" "}
        / {screenFake[screen - 1]}
      </div>
      <Dropdown overlay={menu} placement="bottomLeft">
        <Buttonanguage>
          {" "}
          <IConLanguage
            src={language === "la" ? iconLaos : iconUnitedStates}
            alt=""
          />{" "}
          {language}
        </Buttonanguage>
      </Dropdown>
    </PathWrapper>
  );
}

export const HeaderScreen = ({ value, setValue }) => {
  const { t } = useTranslation();
  return (
    <div className="segmented">
      <Segmented
        options={[
          { label: t("dashBoard.obstetricDept"), value: EDepartment.OBSTETRIC },
          { label: t("dashBoard.pediatricDept"), value: EDepartment.PEDIATRIC },
        ]}
        value={value}
        onChange={setValue}
        size="large"
      />
    </div>
  );
};
