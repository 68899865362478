import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { getListQuanter } from "../../../helpers/getListQuanter";
import { faker } from "@faker-js/faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
      label: false,
    },
    title: {
      display: true,
    },
  },
  style: {
    strokewidth: 10,
  },
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 10,
      bottom: 10,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      ticks: {
        stepSize: 10,
      },
      display: true,
      min: 0,
      max: 110,
      // callback: function (value, index, values) {
      //   if (value == 105) {
      //     return "";
      //   }
      //   return value + "%";
      // },
    },

    // yAxes: [
    //   {
    //     ticks: {
    //       min: 0,
    //       max: 105,
    //       callback: function (value, index, values) {
    //         if (value == 105) {
    //           return "";
    //         }
    //         return value + "%";
    //       },
    //     },
    //   },
    // ],
  },
};

const LinePointLaos = React.memo(({ data1, time, data }) => {
  const { t } = useTranslation();
  const labels = getListQuanter(t("indicatior.quarter")).reverse();

  const position = (value) => {
    if (!value && value !== 0) return "0";
    if (value === 0) return "16";
    if (value === 100 || String(value)?.length >= 3) {
      return "6";
    }
    if (String(value) === 2) return "18";
    return "12";
  };

  const renderCircle = (innerText = "100", size = 30, color = "red") => {
    const svg_encoded = encodeURIComponent(`
    <svg width="39" height="42" viewBox="0 0 49 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="39" height="42" rx="24.5" fill="${color}"/>
      <text x="${position(
        innerText
      )}" y="28" font-size="1em" font-weight="bold" fill="white">${innerText}</text>
    </svg>
    `);
    const svg_uri = `data:image/svg+xml;charset=UTF-8,${svg_encoded}`;
    const illu = new Image(size, size);
    illu.src = svg_uri;
    return illu;
  };

  function difference(a, b) {
    return Math.abs(a - b);
  }

  function findMin(a, b) {
    if (a > b) {
      return b;
    }
    return a;
  }

  const dataFormat = (dataProps) => {
    let sum = 0;
    const listNull = [];
    const format = dataProps?.map((element, index) => {
      if (element !== null) {
        sum++;
      }
      if (sum !== 0 && element === null) {
        let space = 0;
        let nextData = [];
        let leftData = null;
        let min = 0;
        let max = [];
        for (let i = 0; i < dataProps?.length; i++) {
          if (index > i && dataProps[i] !== null) {
            leftData = dataProps[i];
            min = i;
          }
          if (index < i && dataProps[i] !== null) {
            nextData.push(dataProps[i]);
            max.push(i);
          }
          if (dataProps[i] === null) {
            space += 1;
          }
        }
        if (nextData?.length > 0 && space !== 0 && leftData !== null) {
          const TU =
            (difference(leftData, nextData[0]) * (index - min)) /
            (max[0] - min);
          listNull.push(index);
          return TU + findMin(leftData, nextData[0]);
        }
      }
      return element;
    });
    return {
      format,
      listNull,
    };
  };

  const dataSK = {
    labels,
    datasets: [
      {
        label: t("provinceData.data_3"),
        data: dataFormat(data[1])?.format || [],
        borderColor: "#f1c40f",
        backgroundColor: "rgb(241, 196, 15,0.5)",
        pointStyle: dataFormat(data[1])?.format?.map((element) =>
          renderCircle(element, 25, `rgb(241, 196, 15,0.8)`)
        ),
        borderWidth: 2,
        pointRadius: (element) => {
          const checked = dataFormat(data[1])?.listNull.find(
            (elementFind) => elementFind === element?.index
          );
          return checked ? 0 : 4;
        },
        pointHoverRadius: (element) => {
          const checked = dataFormat(data[1])?.listNull.find(
            (elementFind) => elementFind === element?.index
          );
          return checked ? 0 : 4;
        },
      },
      {
        label: t("provinceData.data_2"),
        data: dataFormat(data[0])?.format || [],
        borderColor: "#0984e3",
        backgroundColor: "rgb(9, 132, 227,0.5)",
        pointStyle: dataFormat(data[0])?.format?.map((element) =>
          renderCircle(element, 25, `rgb(9, 132, 227, 0.8)`)
        ),
        borderWidth: 2,
        pointRadius: (element) => {
          const checked = dataFormat(data[0])?.listNull.find(
            (elementFind) => elementFind === element?.index
          );
          return checked ? 0 : 4;
        },
        pointHoverRadius: (element) => {
          const checked = dataFormat(data[0])?.listNull.find(
            (elementFind) => elementFind === element?.index
          );
          return checked ? 0 : 4;
        },
      },
      {
        label: t("provinceData.data_9"),
        data: dataFormat(data[3])?.format || [],
        borderColor: "#2ecc71",
        backgroundColor: "rgb(46, 204, 113,0.5)",
        pointStyle: dataFormat(data[3])?.format?.map((element) =>
          renderCircle(element, 25, `rgb(46, 204, 113, 0.8)`)
        ),
        borderWidth: 2,
        pointRadius: (element) => {
          const checked = dataFormat(data[3])?.listNull.find(
            (elementFind) => elementFind === element?.index
          );
          return checked ? 0 : 4;
        },
        pointHoverRadius: (element) => {
          const checked = dataFormat(data[3])?.listNull.find(
            (elementFind) => elementFind === element?.index
          );
          return checked ? 0 : 4;
        },
      },
      {
        label: t("provinceData.data_7"),
        data: dataFormat(data[2])?.format || [],
        borderColor: "#bdc3c7",
        backgroundColor: "rgb(189, 195, 199,0.5)",
        pointStyle: dataFormat(data[2])?.format?.map((element) =>
          renderCircle(element, 25, `rgb(189, 195, 199,0.8)`)
        ),
        borderWidth: 2,
        pointRadius: (element) => {
          const checked = dataFormat(data[2])?.listNull.find(
            (elementFind) => elementFind === element?.index
          );
          return checked ? 0 : 4;
        },
        pointHoverRadius: (element) => {
          const checked = dataFormat(data[2])?.listNull.find(
            (elementFind) => elementFind === element?.index
          );
          return checked ? 0 : 4;
        },
      },
      {
        label: t("provinceData.passLevel"),
        fill: false,
        backgroundColor: "#e74c3c",
        borderColor: "#e74c3c",
        borderDash: [5, 5],
        data: labels.map(() => faker.datatype.number({ min: 80, max: 80 })),
        borderWidth: 2,
        pointStyle: "hidden",
        pointRadius: 0,
        pointHoverRadius: 0,
        datalabels: { display: false },
      },
    ],
  };

  return <Line options={options} data={dataSK} />;
});

export default LinePointLaos;
