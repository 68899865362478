import moment from "moment";

const getQuarter = (d, txtQuarter) => {
  const quan = Math.floor(moment(d).month() / 3) + 1;
  return `${txtQuarter || "Q"}${quan}/${moment(d).year()}`;
};

export const getListQuanter = (txtQuarter) => {
  const arr = [];
  // Create an array with 8 elements representing the last 8 quarters
  Array.from({ length: 8 }, (_, index) => index).forEach((index) => {
    if (index === 0) {
      arr.push(moment(new Date()));
    } else {
      const month = moment(arr[index - 1]).subtract(3, "months");
      arr.push(month);
    }
  });
  const newQuater = arr.map((element) => {
    return getQuarter(element, txtQuarter);
  });
  return newQuater;
};


// export const getListQuanter = (txtQuarter) => {
//   const arr = [];
//   [-1, 0, 1, 2].forEach((element, index) => {
//     if (index === 0) {
//       arr.push(moment(new Date()));
//     } else {
//       const month = moment(arr[element]).subtract(3, "months");
//       arr.push(month);
//     }
//   });
//   const newQuater = arr.map((element) => {
//     return getQuarter(element, txtQuarter);
//   });
//   return newQuater;
// };
