/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from 'react';
import { Button, Spin } from 'antd';
import { LinkHeader, SurveyLinkWrapper } from './styled';

import ChartLink from './chart';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { sendGet } from '../../api/axios';

const SurveyLink = () => {
  const { t } = useTranslation();
  const user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null;
  const cityURlsSelected = useSelector((state) => state.data.cityURlsSelected);
  const currentQuarter = useSelector((state) => state?.data?.currentQuarter);

  const [dataTableChart, setDatableChart] = useState([]);
  const [linkUrl, setLineUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const linkSurevy = useMemo(() => {
    return cityURlsSelected?.survey_url || null;
  }, [cityURlsSelected]);
  const getDataDashboard = async (code) => {
    setIsLoading(true);
    let newcode = user?.province_code;
    if (
      user?.is_superuser === 'True' &&
      cityURlsSelected &&
      cityURlsSelected?.code !== -1
    ) {
      newcode = cityURlsSelected?.code;
    }
    try {
      const data = await sendGet(
        `dm/data/province/survey_progress?province_code=${newcode}`
      );
      if (data?.status === 'successful') {
        setLineUrl(data?.survey_url);
        const dataClone = [...data?.data[4 + currentQuarter]?.data];
        const chartData = [];
        chartData.push([
          'hospital_name',
          t('surveyLink.sectionMothers'),
          t('surveyLink.normalMother'),
          { role: 'style' },
        ]);
        dataClone.forEach((element) => {
          const item = [];
          item.push(element.hospital_name);
          item.push(element.no_SM);
          item.push(element.no_ST);
          item.push('stroke-width: 4');
          chartData.push(item);
        });
        setDatableChart(chartData || []);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDataDashboard(cityURlsSelected?.code);
  }, [cityURlsSelected, currentQuarter]);

  return (
    <SurveyLinkWrapper>
      <div className='container'>
        <div className='link-selected'>
          {isLoading ? (
            <div className='loading-wrapper'>
              <Spin size='large' />
            </div>
          ) : (
            <>
              <LinkHeader>
                <Button
                  className='link'
                  onClick={() => {
                    if (!linkSurevy) return;
                    setTimeout(() => {
                      window.open(linkSurevy, '_blank');
                    }, 0);
                  }}
                >
                  {t('surveyLink.link')}
                </Button>
              </LinkHeader>

              {dataTableChart?.length > 0 && (
                <div className='chart'>
                  <ChartLink
                    dataTableChart={dataTableChart}
                    selected={cityURlsSelected}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </SurveyLinkWrapper>
  );
};

export default SurveyLink;
