import React from "react";
import PieChartContainer from "../../components/HomeLaos/PieChartContainer/PieChartContainer";
import { HomeLaosWrapper, TitleWrapper } from "./homeStyle";
import LineChartContainer from "./../../components/HomeLaos/LineChartContainer/LineChartContainer";
import IndicatorsContainer from "./../../components/HomeLaos/IndicatorsContainer/IndicatorsContainer";
import { Spin } from "antd";
import { SpinWrapper } from "./../styled";
import HeaderExport from "../export-data/header";
import TableChart from "../export-data/chart/table-chart";
import { useSelector } from "react-redux";

const HomeLaos = React.memo(({ isLoading }) => {
  const tableData = useSelector((state) => state?.data?.tableData);

  const arrName = [
    "Exclusive breastfeeding (EBF) during hospital stay",
    "Prolonged Skin to Skin contact and Early Initiation of Breastfeeding (EIBF) before separation",
    "Mother receiving breastfeeding screening before discharge",
    "Mother with basic knowledge in terms of breastfeeding after receiving education/counseling",
  ];

  if (isLoading) {
    return (
      <SpinWrapper>
        <Spin size="large" />
      </SpinWrapper>
    );
  }
  return (
    <HomeLaosWrapper id="exportDagtaLaos">
      <div className="page html2pdf__page-break">
        <HeaderExport />
        <PieChartContainer />
      </div>
      <div className="page html2pdf__page-break">
        <HeaderExport />
        <LineChartContainer />
      </div>
      {tableData && (
        <>
          {[3, 2, 9, 7].map((element, index) => (
            <div className="page html2pdf__page-break">
              <HeaderExport />
              <TableChart
                index={element}
                criteria={{
                  criteria: arrName[index],
                }}
                department={"SK"}
              />
            </div>
          ))}
        </>
      )}
      <div className="page html2pdf__page-break">
        <HeaderExport />
        <IndicatorsContainer />
      </div>
    </HomeLaosWrapper>
  );
});

export default HomeLaos;
