import React from "react";
const MenuLanguage = () => {
  return <div />;
  // const menu = (
  //   <Menu
  //     items={[
  //       {
  //         key: "1",
  //         label: (
  //           <div
  //             onClick={() => {
  //               i18next.changeLanguage("vi");
  //               setLanguage("vi");
  //             }}
  //           >
  //             <IConLanguage src={iconVietnam} alt="" /> {t("common.vietNam")}
  //           </div>
  //         ),
  //       },
  //       {
  //         key: "2",
  //         label: (
  //           <div
  //             onClick={() => {
  //               i18next.changeLanguage("en");
  //               setLanguage("en");
  //             }}
  //           >
  //             <IConLanguage src={iconUnitedStates} alt="" />{" "}
  //             {t("common.engLish")}
  //           </div>
  //         ),
  //       },
  //     ]}
  //   />
  // );

  // return (
  //   <DropdownLanguage overlay={menu} placement="bottomLeft">
  //     <Buttonanguage>
  //       {" "}
  //       <IConLanguage
  //         src={language === "vi" ? iconVietnam : iconUnitedStates}
  //         alt=""
  //       />{" "}
  //       {language}
  //     </Buttonanguage>
  //   </DropdownLanguage>
  // );
};

export default MenuLanguage;
