/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";

import { Segmented } from "antd";
import { Select, Spin } from "antd";

import html2pdf from "html2pdf.js";

import { useSelector, useDispatch } from "react-redux";
import { FilterWrapper } from "./styled";
import { LAO_API, SCREEN_DEFAULT } from "../../common/ngok";

import {
  storeSetCitiesData,
  storeSetCurrentQuarter,
  storeSetHostpitalData,
  storeSethospitalSelected,
  storeSetCitySelected,
  storeSetCityURlsSelected,
} from "../../store/data-reducer";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { storeSetTab } from "../../store/document-reducer";
import Loading from "../../components/common/Loading/Loading";
import moment from "moment";
import { getListQuanter } from "../../helpers/getListQuanter";
import { sendGet } from "../../api/axios";

const FilterComponent = ({ disabled, screen }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const patch = location?.pathname || "/dashboard";
  const language = useSelector((state) => state?.auth?.language);

  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
  const cityURlsSelected = useSelector((state) => state.data.cityURlsSelected);

  const hospitalSelected = useSelector(
    (state) => state?.data?.hospitalSelected
  );
  const citySelected = useSelector((state) => state.data.citySelected);
  const currentQuarter =
    useSelector((state) => state?.data?.currentQuarter) || 0;
  const citiesData = useSelector((state) => state.data.citiesData);
  const hostPitals = useSelector((state) => state.data.hostPitals);
  const tabDocument = useSelector((state) => state.document.tab);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingScreen, setIsLoadingScreen] = useState(false);
  const defaultCity = useMemo(() => {
    return citySelected?.name || "";
  }, [hospitalSelected, citiesData]);
  const exportPdfData = async () => {
    const hostpitalName =
      hospitalSelected?.name?.toUpperCase()?.replaceAll(" ", "_") || "";

    const fileName = `LAOS_COE_REPORT_${hostpitalName}_${listQuater[
      7 - currentQuarter
    ]?.replace("/", "_")}`;
    setIsLoadingScreen(true);
    const element = document.getElementById("exportDagtaLaos");
    const opt = {
      margin: 1,
      image: { type: "jpeg", quality: 0.98 },
      filename: fileName,
      html2canvas: { scale: 1 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      pagebreak: { mode: ["legacy"] },
    };
    html2pdf().set(opt);
    html2pdf()
      .from(element)
      .save(fileName)
      .then(() => {
        setTimeout(() => {
          setIsLoadingScreen(false);
        }, 1000);
      });
  };

  const exportRaw = async () => {
    try {
      setIsLoadingScreen(true);
      const response = await sendGet(`/dm/data/raw/export?lang=en`);
      if (response?.status === "successful") {
        setTimeout(() => {
          window.open(`${LAO_API}/${response?.data?.url}`);
        }, 0);
      }
    } catch (error) {
    } finally {
      setIsLoadingScreen(false);
    }
  };

  const getCities = async () => {
    try {
      const response = await sendGet(`${LAO_API}/dm/data/province?info=all`);

      const reDataCity = response?.provinces?.map((element) => ({
        ...element,
        vie_name: element.name,
        name: language === "la" ? element?.laos_name : element.name,
      }));
      reDataCity.unshift({
        name: "-- ALL --",
        vie_name: "-- ALL --",
        laos_name: "-- ALL --",
        code: -1,
      });
      console.log("reDataCityreDataCity", language);
      dispatch(storeSetCitiesData(reDataCity || []));
      dispatch(
        storeSetCityURlsSelected(
          reDataCity.find((element) => element.code == user.province_code) || []
        )
      );
    } catch (error) {}
  };

  const getHostPital = async (code) => {
    setIsLoading(true);
    try {
      const ressponse = await sendGet(
        `${LAO_API}/dm/data/province?code=${code}&info=hospitals`
      );
      const reDataCity = ressponse?.data?.hospitals?.map((element) => ({
        ...element,
        vie_name: element.name,
        name: element?.laos_name,
      }));
      reDataCity.unshift({
        name: "",
        vie_name: "",
        laos_name: "",
        code: -1,
      });
      dispatch(storeSetHostpitalData(reDataCity || []));
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const listQuater = useMemo(() => {
    return getListQuanter(t("indicatior.quarter"));
  }, [moment, t]);

  const listCityURls = useMemo(() => {
    return citiesData?.filter((element) => element.code !== -1) || [];
  }, [citiesData]);
  useEffect(() => {
    getCities();
  }, []);
  return (
    <FilterWrapper>
      {isLoadingScreen && <Loading />}
      <div className="adress">
        {patch === SCREEN_DEFAULT[6] && (
          <div
            className="back"
            onClick={() => navigate(`${SCREEN_DEFAULT[1]}`)}
          >
            {t("filter.back")}
          </div>
        )}
        {!disabled ||
        (patch === SCREEN_DEFAULT[2] && user?.is_superuser === "True") ? (
          <span>{t("filter.city")}</span>
        ) : (
          <div />
        )}
        {!disabled ||
        (patch !== SCREEN_DEFAULT[2] &&
          patch !== SCREEN_DEFAULT[3] &&
          patch !== SCREEN_DEFAULT[4] &&
          patch !== SCREEN_DEFAULT[7] &&
          user?.is_superuser === "True") ? (
          <Select
            defaultValue={defaultCity || t("common.none")}
            value={citySelected?.name || t("common.none")}
            className="select-city"
            onChange={(e) => {
              getHostPital(citiesData[e].code);
              dispatch(storeSetHostpitalData([]));
              dispatch(storeSethospitalSelected(null));
              dispatch(storeSetCitySelected(citiesData[e]));
            }}
            // disabled={disabled && screen !== 2}
          >
            {citiesData?.map((element, index) => {
              return (
                <Select.Option key={String(index)}>
                  {index + 1}. {element?.name}
                </Select.Option>
              );
            })}
          </Select>
        ) : (
          <div />
        )}
        {patch === SCREEN_DEFAULT[2] && user?.is_superuser === "True" ? (
          <Select
            value={cityURlsSelected?.name}
            className="select-city"
            onChange={(e) => {
              dispatch(storeSetCityURlsSelected(listCityURls[e]));
            }}
          >
            {listCityURls?.map((element, index) => {
              return (
                <Select.Option key={String(index)}>
                  {index + 1}. {element?.name}
                </Select.Option>
              );
            })}
          </Select>
        ) : (
          <div />
        )}
        {!disabled && <span className="hostpital">{t("filter.hospital")}</span>}
        {!disabled && (
          <Select
            defaultValue={hospitalSelected?.name || ""}
            value={hospitalSelected?.name || ""}
            className="select-hostpital"
            onChange={(e) => {
              dispatch(storeSethospitalSelected(hostPitals[e]));
            }}
            disabled={disabled}
          >
            {isLoading ? (
              <Select.Option>
                <Spin />
              </Select.Option>
            ) : (
              <>
                {hostPitals?.map((element, index) => {
                  return (
                    <Select.Option key={String(index)}>
                      {element?.name || " "}
                    </Select.Option>
                  );
                })}
              </>
            )}
          </Select>
        )}
        {(!disabled || !hospitalSelected || patch === SCREEN_DEFAULT[2]) &&
        patch !== SCREEN_DEFAULT[4] &&
        patch !== SCREEN_DEFAULT[3] &&
        patch !== SCREEN_DEFAULT[7] ? (
          <div>
            {listQuater?.length > 0 && (
              <Select
                defaultValue={listQuater[7 - currentQuarter]}
                value={listQuater[7 - currentQuarter]}
                className="select-quarter"
                onChange={(e) => {
                  dispatch(storeSetCurrentQuarter(7 - e));
                }}
              >
                {listQuater?.map((element, index) => {
                  return (
                    <Select.Option key={String(index)}>{element}</Select.Option>
                  );
                })}
              </Select>
            )}
          </div>
        ) : null}
        {/* {patch === SCREEN_DEFAULT[3] && <RangePicker className="datePicker" />} */}
        {patch === SCREEN_DEFAULT[4] && (
          <Segmented
            options={[
              { label: t("document.decisionInstruction"), value: 1 },
              { label: t("document.awardedHospitals"), value: 2 },
            ]}
            value={tabDocument}
            onChange={(e) => {
              dispatch(storeSetTab(e));
            }}
            size="large"
          />
        )}
      </div>
      {patch === SCREEN_DEFAULT[3] && (
        <div className="export" onClick={exportRaw}>
          Export Data
        </div>
      )}
      {(patch === SCREEN_DEFAULT[1] || patch === "/") && (
        <div className="export" onClick={exportPdfData}>
          {t("filter.generateReport")}
        </div>
      )}
    </FilterWrapper>
  );
};

export default FilterComponent;
