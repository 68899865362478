export const DATA_TITLE = {
  _id: "Submission ID",
  _submission_time: "Submission time",
  bmon: "Child's birth month",
  bqrt: "Child's quarter of birth",
  byear: "Child's year of birth",
  formhub_uuid: "Form UUID",
  hospcode: "Hospital code",
  hospital: "Name of hospital surveyed",
  id: "Hospital ID",
  moage: "Mother's age",
  moname: "Mother's name",
  phone: "Phone number",
  province: "City",
};
