/* eslint-disable react-hooks/exhaustive-deps */
import "./App.css";
import Dashboard from "./dashboard/index";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { Suspense } from "react";
import LoginPage from "./pages/login";
import AppsPage from "./pages/apps";
import { Provider, useSelector } from "react-redux";
import { persistor, store } from "./rootStore";
import { useEffect } from "react";
import { PersistGate } from "redux-persist/integration/react";
import StyleGlobal from "./styles";

const RootRouter = function () {
  const location = useLocation();
  const navigate = useNavigate();
  const token = useSelector((state) => state?.auth?.token);

  useEffect(() => {
    if (token) {
    } else {
      if (location?.pathname === "/") {
        return;
      }
      navigate("/login");
    }
  }, [token]);
  return (
    <div>
      <Suspense>
        {token ? (
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/survey_urls" element={<Dashboard />} />
            <Route path="/raw_data" element={<Dashboard />} />
            <Route path="/documents" element={<Dashboard />} />
            <Route path="/user_management" element={<Dashboard />} />
            <Route path="/exportData" element={<Dashboard />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/apps" element={<AppsPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<AppsPage />} />
          </Routes>
        )}
      </Suspense>
    </div>
  );
};

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
          <StyleGlobal />
          <RootRouter />
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
