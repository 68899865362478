import React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PieChart from "../../PieChart/PieChart";
import {
  PieChartContainerWrapper,
  RowWrapper,
} from "./pieChartContainerStyle";

const PieChartContainer = () => {
  const { t } = useTranslation();
  const dashboardData = useSelector((state) => state?.data?.dashboardData);
  const currentQuarter = useSelector((state) => state?.data?.currentQuarter);

  const pieData1 = useMemo(() => {
    if (!dashboardData || !dashboardData[4 + currentQuarter]?.data?.extra[1]) {
      return [0, 0, 0, 0, 0];
    }
    const data = dashboardData[4 + currentQuarter]?.data?.extra[1];
    const listKey = {
      Lao_loum: t("pieName.Lao_loum"),
      Hmong: t("pieName.Hmong"),
      Khmu: t("pieName.Khmu"),
      Akha: t("pieName.Akha"),
      Other: t("pieName.Other"),
    };
    const result = Object.keys(data?.result).map((key) => ({
      type: `${listKey[key]}`,
      value:
        data?.result[key] && data?.result[key] >= 0 ? data?.result[key] : 0,
    }));
    return result;
  }, [currentQuarter, dashboardData, t]);

  const pieData2 = useMemo(() => {
    if (!dashboardData || !dashboardData[4 + currentQuarter]?.data?.extra[2]) {
      return [0, 0, 0, 0, 0];
    }
    const data = dashboardData[4 + currentQuarter]?.data?.extra[2];
    const result = Object.keys(data?.result).map((key, index) => ({
      type: `${
        key === "fullterm" ? t("pieName.fullterm") : t("pieName.preterm")
      }`,
      value:
        data?.result[key] && data?.result[key] >= 0 ? data?.result[key] : 0,
    }));
    return result;
  }, [currentQuarter, dashboardData, t]);

  const pieData3 = useMemo(() => {
    if (!dashboardData || !dashboardData[4 + currentQuarter]?.data?.extra[3]) {
      return [0, 0, 0, 0, 0];
    }
    const data = dashboardData[4 + currentQuarter]?.data?.extra[3];
    const result = Object.keys(data?.result).map((key, index) => ({
      type: `${
        key === "vaginal" ? t("piaName.vaginal") : t("piaName.cesacean")
      }`,
      value:
        data?.result[key] && data?.result[key] >= 0 ? data?.result[key] : 0,
    }));
    return result;
  }, [currentQuarter, dashboardData, t]);

  const pieData4 = useMemo(() => {
    if (!dashboardData || !dashboardData[4 + currentQuarter]?.data?.extra[4]) {
      return [0, 0, 0, 0, 0];
    }
    const data = dashboardData[4 + currentQuarter]?.data?.extra[4];
    const result = Object.keys(data?.result).map((key, index) => ({
      type: `${index === 0 ? t("pieName.over24") : t("pieName.less24")}`,
      value:
        data?.result[key] && data?.result[key] >= 0 ? data?.result[key] : 0,
    }));
    return result;
  }, [currentQuarter, dashboardData, t]);

  return (
    <PieChartContainerWrapper>
      <RowWrapper>
        <PieChart pieData={pieData1} pieName={t("pieName.ethnicity")} />
        <PieChart pieData={pieData2} pieName={t("pieName.termOfBirth")} />
      </RowWrapper>
      <RowWrapper>
        <PieChart pieData={pieData3} pieName={t("pieName.birthMethod")} />
        <PieChart pieData={pieData4} pieName={t("pieName.lengthHospital")} />
      </RowWrapper>
    </PieChartContainerWrapper>
  );
};

export default PieChartContainer;
