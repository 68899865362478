import React from "react";
import { HeaderExportWrapper } from "./styled";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getListQuanter } from "../../../helpers/getListQuanter";

const HeaderExport = () => {
  const { t } = useTranslation();
  const currentQuarter =
    useSelector((state) => state?.data?.currentQuarter) || null;
  const hospitalSelected = useSelector(
    (state) => state?.data?.hospitalSelected
  );
  return (
    <HeaderExportWrapper>
      <div className="title">{t("obstetricsData.headerExport")}</div>
      <div className="last-update">
        {t("exportData.updateTo")}{" "}
        {getListQuanter(t("indicatior.quarter")).reverse()[currentQuarter]} -{" "}
        <span className="hospital">{hospitalSelected?.name || t("indicatior.laos")}</span>
      </div>
    </HeaderExportWrapper>
  );
};

export default HeaderExport;
