import styled from 'styled-components';
import { rem } from '../../helpers/rem/px-to-rem';

export const HomeLaosWrapper = styled.div`
  width: ${rem(800)};
  padding: 30px;
  margin-right: 0 !important;
  .page {
    padding: ${rem(20)} ${rem(5)};
    margin-top: ${rem(30)};
    background-color: white;
    height: fit-content;
    /* box-shadow: 3px 3px 3px 3px #dcdde1; */
  }
`;
export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
