/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react";
import { RowDataWrapper } from "./styled";
import { Spin, Table } from "antd";
import { useState } from "react";
import { LAO_API } from "../../common/ngok";
import { useSelector } from "react-redux";
import { sendPost } from "../../api/axios";
import { getListQuanter } from "../../helpers/getListQuanter";
import { DATA_TITLE } from "./fakeData";
import { useTranslation } from "react-i18next";

const RowData = React.memo(() => {
  const { t } = useTranslation();
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
  const citiesData = useSelector((state) => state.data.citiesData);
  const citySelected = useSelector((state) => state.data.citySelected);
  const currentQuarter = useSelector((state) => state?.data?.currentQuarter);
  const listQuanter = getListQuanter(t("indicatior.quarter"));

  const [isLoading, setIsLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [numEntries, setNumEntries] = useState({});

  const time = useMemo(() => {
    const timeString = listQuanter[currentQuarter]?.split("/");
    return {
      quarter: timeString[0][1] || "",
      year: timeString[1] || "",
    };
  }, [currentQuarter, listQuanter]);
  const getDataRow = async (page) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const myHeaders = new Headers({
      Authorization: "Token " + user?.token,
      "Content-Type": "application/x-www-form-urlencoded",
    });
    fetch(`${LAO_API}/dm/data/raw?page=${page}&size=5`, {
      method: "GET",
      headers: myHeaders,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === "successful") {
          setRowData(data?.data?.object_list);
          setNumEntries({
            total: data?.data?.num_entries,
            numPages: data?.data?.num_pages,
            page_number: data?.data?.page_number,
          });
        }
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getDataRow(1);
  }, []);

  const arr = useMemo(() => {
    if (!rowData || rowData?.length === 0) {
      return null;
    }
    return (
      Object?.keys(rowData[0])?.map((val, idx) => ({
        title: `${DATA_TITLE[val] || val}`,
        dataIndex: `${val}`,
        key: `${val}`,
      })) || null
    );
  }, [rowData]);

  const columns = [
    {
      title: "City",
      dataIndex: "hosp_province",
      key: "hosp_province",
      render: (record) => {
        return (
          <div>
            {citiesData?.find((element) => element.code === record)?.name || ""}
          </div>
        );
      },
    },
    {
      title: "Unit conducting the survey",
      dataIndex: "hosp_operator",
      key: "hosp_operator",
    },
    {
      title: "Survey Criteria",
      dataIndex: "hosp_criteria_name",
      key: "hosp_criteria_name",
    },
    {
      title: "Name of hospital surveyed",
      dataIndex: "hosp_hospname",
      key: "hosp_hospname",
    },
    {
      title: "Unit conducting the survey",
      dataIndex: "hosp_byt_syt",
      key: "hosp_byt_syt",
    },
    {
      title: "Mother's name",
      dataIndex: "minf_moname",
      key: "minf_moname",
    },
    {
      title: "Phone number",
      dataIndex: "minf_phone",
      key: "minf_phone",
    },
    {
      title: "q00",
      dataIndex: "q00",
      key: "q00",
    },
    {
      title: "Quanter",
      dataIndex: "qrt",
      key: "qrt",
    },
    {
      title: "Child's year of birth",
      dataIndex: "byear",
      key: "byear",
    },
    {
      title: "Child's birth month",
      dataIndex: "bmon",
      key: "bmon",
    },
    {
      title: "q01",
      dataIndex: "q01",
      key: "q01",
    },
    // {
    //   title: "q01a",
    //   dataIndex: "q01a",
    //   key: "q01a",
    // },
    // {
    //   title: "q01b",
    //   dataIndex: "q_current_bf_q01b",
    //   key: "q_current_bf_q01b",
    // },
    {
      title: "q02",
      dataIndex: "q02",
      key: "q02",
    },
    {
      title: "q02a",
      dataIndex: "q_cbf_q02a",
      key: "q_cbf_q02a",
    },
    {
      title: "q02b",
      dataIndex: "q_cbf_q02b",
      key: "q_cbf_q02b",
    },
    {
      title: "q03",
      dataIndex: "q_cbf_q03",
      key: "q_cbf_q03",
    },
    {
      title: "q03/1",
      dataIndex: "q_cbf_q03_1",
      key: "q_cbf_q03_1",
    },
    {
      title: "q03a",
      dataIndex: "q_cbf_q03a",
      key: "q_cbf_q03a",
    },
  ];
  const handleClick = async () => {
    try {
      const response = await sendPost(
        "/dm/data/raw/export_province_quarter_report",
        {
          year: Number(time.year),
          quarter: Number(time.quarter),
          province: citySelected?.code,
        }
      );
      if (response?.status === "OK") {
        window.open(`${LAO_API}/${response?.data?.url}`);
      }
    } catch (error) {}
  };
  return (
    <RowDataWrapper>
      <div>
        {arr ? (
          <Table
            className="table-row-data"
            columns={arr}
            dataSource={rowData}
            loading={isLoading}
            key={(recod) => recod.hosp_hospname}
            showSizeChanger={false}
            pagination={{
              defaultPageSize: 5,
              showSizeChanger: true,
              total: numEntries?.total,
              current: numEntries?.page_number,
              pageSizeOptions: ["5"],
              showTotal: (total, range) => (
                <div className="title-pagination">
                  <div className="position-absolute left-20">
                    {range[0]}-{range[1]} from {total}
                  </div>
                </div>
              ),
              locale: { items_per_page: "" },
              onChange: (pageChange) => {
                getDataRow(pageChange);
              },
            }}
          />
        ) : (
          <Spin size="large" />
        )}
      </div>
    </RowDataWrapper>
  );
});

export default RowData;
