import styled from "styled-components";

export const NoData = styled.div`
  margin-top: 1rem;
  text-align: center;
  color: gray;
`;
export const RedText = styled.span`
  color: red;
`;
export const TableChartWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  .space {
    height: 0.625rem;
  }
  .border-right-none {
    border-right: none !important;
  }
  .border-top-none {
    border-top: none !important;
  }
  .margin-top-2 {
    margin-top: 2px;
  }
  .title-content {
    color: #00a8ff;
    font-weight: 700;
  }
  .padding-left-30 {
    padding-left: 30px !important;
  }
  .background-color {
    background-color: #00a8ff;
    color: white;
  }
  .warning {
    color: tomato;
    text-decoration: underline;
  }
  .header-chart {
    display: flex;
    flex-direction: row;
    border: 1px solid #000;
    .criteria {
      padding: 4px 4px;
      width: 40%;
      border-right: 1px solid #000;
      font-weight: 500;
      text-align: center;
    }
    .quarter {
      padding: 4px 0;
      width: 15%;
      border-right: 1px solid #000;
      text-align: center;
      font-weight: 500;
      font-size: 11px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
