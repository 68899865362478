import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import vi from "../locales/vi.json";
import en from "../locales/en.json";
import la from "../locales/la.json";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    // lng: "la",
    fallbackLng: "la",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      la: { translation: la },
      en: { translation: en },
    },
  });

export default i18n;
